/**
 * reserve-naturelle
 *
 * @author Serge RIVIÈRE <serge.riviere@bnb.re>
 * @copyright 2017 - B&B Web Expertise
 */

var $ = window.$ = window.jQuery;
$.fn.matchHeight._maintainScroll = true;

/**
 * Set up token for ajax request
 */

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/**
 * Datetimepicker
 */
$.fn.datetimepicker.defaults = {
    autoclose: true,
    clearBtn: true,
    forceParse: true,
    todayBtn: true,
    minuteStep: 30,
    weekStart: 1,
    language: Lang.getLocale(),
};
// Add a quickfix for the clear translation which missing
if (Lang.getLocale() == 'fr') {
    $.fn.datetimepicker.dates['fr'].clear = 'Effacer';
}

/**
 * Attach datetimepicker
 * @param parent_selector
 */
function attachDatetimePicker(parent_selector) {
    parent_selector = parent_selector ? parent_selector : '';

    // Date (by default)
    $(parent_selector + ' .datetimepicker').datetimepicker({
        format: (Lang.getLocale() == 'fr') ? 'dd/mm/yyyy' : 'yyyy/mm/dd',
        linkFormat: "yyyy-mm-dd",
        startView: 'year',
        minView: 'month'
    });

    // Datetime
    $(parent_selector + ' .datetimepicker-datetime').datetimepicker({
        format: (Lang.getLocale() == 'fr') ? 'dd/mm/yyyy hh:ii' : 'yyyy/mm/dd hh:ii',
        linkFormat: "yyyy-mm-dd hh:ii:00",
        startView: 'year',
        minView: 'hour'
    });
    // Time
    $(parent_selector + ' .datetimepicker-time').datetimepicker({
        format: 'hh:ii',
        linkFormat: "hh:ii:00",
        todayBtn: false,
        startView: 'day',
        maxView: 'day',
        minView: 'hour',
        minuteStep: 1,
        title: Lang.get('general.select-duration'),
    });
}

/**
 * Attach Remote Modal
 */
function attachRemoteModal(parent_selector) {
    parent_selector = parent_selector ? parent_selector : '';
    $(parent_selector + ' a.remote-modal').on('click', function (event) {

        // Stop default event
        event.preventDefault();
        event.stopPropagation();

        var dataContainer = $(this).data('container') ? $(this).data('container') : '#general-modal-container';
        var target = $(this).data('target');
        var href = $(this).attr('href');
        var dataForm = $($(this).data('form')).serialize();
        var method = $(this).data('method') ? $(this).data('method') : 'GET';

        // Ajax call
        var modalAjax = $.ajax({
            cache: 'false',
            type: method,
            url: href,
            data: dataForm,
            beforeSend: function (xhr) {
                $('#loading-modal').modal('show');
            }
        }).done(function (data) {
            $('#loading-modal').modal('hide');
            $(dataContainer).html(data);
            $(target).modal();
        }).fail(function (data) {
            console.log('Ajax call failed: ' + data.statusText);
            alert(Lang.get('errors.ajax'));
        });
    });
}

/**
 * Initialize photoswipe gallery image
 * @param gallerySelector
 */
function initPhotoSwipeFromDOM(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
            params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if(pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};

/**
 * On Document Ready
 */
$(document).ready(function($) {

    /**
     * Tooltip
     */
    if($('[data-toggle="tooltip"]').length) {
        $('[data-toggle="tooltip"]').tooltip({
            animated: 'fade',
            html: true
        });
    }

    /**
     * Select2
     */
    if($('.select2').length) {
        $('.select2').select2({
            allowClear: true
        });
    }

    /**
     * Conditionize
     */
    if($('.conditional').length) {
        $('.conditional').conditionize();
    }

    /**
     * Remote Modal handler
     */
    attachRemoteModal('body');

    /**
     * DatetimePicker handler
     */
    attachDatetimePicker('body');

    /**
     * Photoswipe (photo-gallery)
     */
    if ($('.photoswipe-gallery').length)
        initPhotoSwipeFromDOM('.photoswipe-gallery');

    /**
     * Match-height
     */
    if ($('.match-height').length)
        $('.match-height').matchHeight();

    /**
     * Smooth Scrolling for anchor
     */
    $('a[href*="#"]:not([href="#"], .carousel-control, [data-toggle="collapse"], [data-toggle="tab"])').on('click', function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                /**
                 * Quickfix for anchor because of fixed nav (-200)
                 */
                $('html, body').animate({
                    scrollTop: target.offset().top - 116
                }, 1000, null, function() {
                    $('.match-height').matchHeight();
                });
                return false;
            }
        }
    });
    if (window.location.hash != '') {
        $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top - 116
        }, 1000, null, function() {
            $('.match-height').matchHeight();
        });
        return false;
    }

    /**
     * Back To Top button
     */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });


});
